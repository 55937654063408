// import {get,put,del,post} from '../../utils/request'
// import {get,put,del,post} from '../../utils/request'

import {get} from "../../utils/request";

/**
 * 其他页面查询当前用户名片
 */
export function getSimpleUserCard(){
    return get(
        `/user/usercard/getSimpleUserCard`,
    )
}
