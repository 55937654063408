<template>
  <div class="calculator-container">
    <header class="header">
      <van-nav-bar
          title="装修计算器"
          left-arrow
          @click-left="handleBlack"
          safe-area-inset-top
          v-if="showHeader"
      >
      </van-nav-bar>
    </header>
    <article class="calculator-article">
      <div class="calculator-article-content">
        <div class="title">
          <span></span>
          <p>你家的装修预算</p>
          <span></span>
        </div>
        <div class="calculator-screen">
          <div v-for="(it,index) in numList" :key="index">
            <div class="font-num" v-if="it!='.'">
              <div class="segment" :class="{c_clr:it==4||it==1}"></div>
              <div class="segment" :class="{c_clr:it==5||it==6}"></div>
              <div class="segment" :class="{c_clr:it==2}"></div>
              <div class="segment" :class="{c_clr:it==4||it==1||it==7}"></div>
              <div class="segment" :class="{c_clr:it==4||it==1||it==3||it==5||it==7||it==9}"></div>
              <div class="segment" :class="{c_clr:it==2||it==1||it==3||it==7}"></div>
              <div class="segment" :class="{c_clr:it==7||it==1||it==0}"></div>
            </div>
            <div v-if="it=='.'" class="point_cls"></div>
          </div>
          <span class="CNY_cls">元</span>
        </div>
        <div class="cost">
          <span>人工费: {{ laborCost }}元</span>
          <span>材料费: {{ materialCost }}元</span>
          <span>设计费: {{ designCost }}元</span>
          <span>质检费: {{ qualityCost }}元</span>
        </div>
        <div class="expense-inp">
          <div class="expense-inp-item">
            <div @click="handleChioceArea">{{ selectCity?selectCity:'选择所在区域' }}</div>
            <div @click="handleChioceType">{{ selectType?selectType:'选择房屋户型' }}</div>
          </div>
          <!--          <div>输入您的房屋面积</div>-->
          <div class="area">
            <van-field v-model="area" placeholder="输入您的房屋面积" type="number" />
            <span class="unit">m²</span>
          </div>
        </div>
        <div>
          <van-button type="primary" color="#289FFF" round block @click="handleShare">分享</van-button>
        </div>
      </div>
    </article>
    <van-popup v-model:show="showCityPicker" round position="bottom">
      <van-picker
          title="请选择所在城市区域"
          :columns="cityColumns"
          @cancel="showCityPicker = false"
          @confirm="onCityConfirm"
          safe-area-inset-bottom
      />
      <!--      <van-cascader-->
      <!--          v-model="code"-->
      <!--          title="请选择所在地区"-->
      <!--          :options="options"-->
      <!--          :field-names="fieldNames"-->
      <!--      />-->
    </van-popup>
    <van-popup v-model:show="showTypePicker" round position="bottom">
      <van-picker item-height="40" title="请选择房屋户型" :columns="columns" @cancel="showTypePicker = false" safe-area-inset-bottom  @confirm="onTypeConfirm"/>
    </van-popup>
    <van-share-sheet
        v-model:show="showShare"
        title="分享"
        :options="shareOptions"
        cancel-text="取消"
        @close="handleCancel"
        @select="onSelect"
        :close-on-click-overlay="false"
        safe-area-inset-bottom
    >
    </van-share-sheet>
    <!-- 弹出分享框 -->
    <div class="share_box">
      <!--    分享海报-->
      <div class="wrapperPoster" id="saveposter" style="z-index: 9999" v-if="showOverlay">
        <div class="poster-details" v-if="showImg" ref="imageWrapper">
          <!--          <img :src="posterPic" crossOrigin="anonymous"/>-->
          <div class="text-qrcode">
            <div class="qrCodeUrl-all">
              <div class="qrCodeUrl-cls">
                <img v-if="qrCodeUrl" :src="qrCodeUrl"/>
              </div>
              <p>长按识别</p>
            </div>
            <div class="left-text">
              <p>10秒估算装修报价</p>
              <p>分享给朋友</p>
            </div>
          </div>
        </div>
        <div v-else class="posterImg-hb">
          <img class="card-img" :src="imgSrc" ref="cardImgRef" />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import {computed, nextTick, reactive, ref, toRefs, watch} from "vue";
import {useRoute, useRouter} from "vue-router";
import html2canvas from "html2canvas";
// import {getCaseDetailsUrl} from "../../api/case";
import  {getCityCode} from '../../utils/city_code'
import {flutterShowToast} from "../../utils/appChannel";
import {getSimpleUserCard} from "../../api/calculator";
// import {addForwardLog} from "../../api/user/userCard";
import {getQrCodeUrl} from "../../api/common";
import {Toast} from "vant";
import { copyStr } from "@/utils/commonUtil";
import {posterShare} from "@/utils/posterShare"
export default {
  name: "index",
  setup(){
    const route = useRoute()
    const router = useRouter()
    let showHeader = computed(() => route.query.hideTitleBar != 1);
    const imageWrapper = ref(null);
    const cardImgRef = ref(null);
    const state = reactive({
      imgSrc:'',
      qrCodeUrl:'',
      posterPic:require('../../assets/images/calculator_poster.png'),
      showOverlay:false,
      showImg:true,
      showTypePicker:false,
      columns:[
        {
          values: ['1室', '2室', '3室', '4室', '4室+'],
          // defaultIndex: 2,
        },
        {
          values: ['1厅', '2厅', '3厅','3厅+'],
          // defaultIndex: 1,
        },
        {
          values: ['1厨', '2厨', '3厨','3厨+'],
          // defaultIndex: 1,
        },
        {
          values: ['1卫', '2卫', '3卫','3卫+'],
          // defaultIndex: 1,
        },
        {
          values: ['1阳台', '2阳台', '3阳台','3阳台+']
          // defaultIndex: 1,
        },
      ],
      shareOptions: [],
      firstOptions: [
        { name: "微信", icon: require("@/assets/icons/weChat2.png") },
        { name: "朋友圈", icon: require("@/assets/icons/friends2.png") },
        { name: "复制链接", icon: require("@/assets/icons/copy-link.png") },
        { name: "分享海报", icon: require("@/assets/icons/share_poster.png")}
      ],
      posterOptions:[
        {name: "微信分享", icon: require("@/assets/icons/weChat2.png")},
        {name: "朋友圈分享", icon: require("@/assets/icons/friends2.png")},
      ],
      showSharePoster:false,
      showShare:false,
      code:'',
      selectCity:'',
      selectType:'',
      showCityPicker:false,
      numList:['','','','','','',''],
      laborCost:0,//人工费
      materialCost:0,//材料费
      designCost:0,//设计费
      qualityCost:0,//质检费
      area:'',
      cityColumns:[],
      options:[],
      user:{},
      qrCodeInfo:{}
    })
    watch(() => state.showShare, (newVal, oldVal) => {
      console.log('新值：',newVal,'老值',oldVal)
      if (state.showShare===false){
        state.showOverlay = false
        state.showImg = true
      }
    })
    const handleCancel = (val) =>{
      state.showImg = true
      console.log("取消分享",val)
    }
    const loadCityCode = () =>{
      state.cityColumns = []
      let area = getCityCode()
      // area.forEach(it=>{
      //   state.cityColumns.push(it)
      //   console.log(it)
      // })
      state.cityColumns = area
    }
    loadCityCode()
    const handleChioceType = ()=>{
      state.showTypePicker = true
    }
    const onTypeConfirm = (val) =>{
      console.log("选择类型",val)
      state.selectType = ''
      val.forEach(it=>{
        state.selectType += it
      })
      state.showTypePicker = false
    }

    const getUserCard = ()=>{
      getSimpleUserCard().then(res=>{
        console.log(res)
        if (res){
          state.user = res
        }
      })
    }
    getUserCard()
    const handleShare = ()=>{
      //sourceId该参数在此无意义，随便填
      getQrCodeUrl({sourceType:5,sourceId:'5'}).then(res=>{
        state.qrCodeInfo = res
        state.qrCodeUrl = res.qrCodeUrl
        state.showSharePoster = true
      })
      state.showShare = true
      state.shareOptions = state.firstOptions
    }
    const onSelect = (index)=>{
      if(index.name === "微信分享"||index.name === "朋友圈分享"){
        if(state.showSharePoster===true){
          let param = {
            imgSrc:state.imgSrc,
            id:state.user.loginUserId,
            bigPosterImgUrl:"https://wca.ujiaku.com/upload/tangYing/fitUp_calculator.png",
          }
          setTimeout(()=>{
            posterShare(index,param)
          })
        }
      }else if(index.name === "复制链接"){
        if (state.showSharePoster){
          copyStr(state.qrCodeInfo.jumpUrl)
          Toast.success("复制成功")
        }
      }else if(index.name === "分享海报"){
        if(state.showSharePoster===true){
          // state.showImg = true
          Toast({
            message: '正在生成海报',
            position: 'bottom',
            forbidClick:true,
            duration:0,
          });
          setTimeout(()=>{
            Toast.clear()
            state.shareOptions = state.posterOptions
            state.showOverlay = true;
            savePoster()
          },500)
        }
      }else {
        let shareType;
        let content;
        let merchantId = state.user.merchantId
        if (index.name === "微信") {
          shareType = "shareToMiniProgram";
          content = `${process.env.VUE_APP_WECHAT_SHARE}/wxShareCalculator/1/${merchantId}`;
        } else if (index.name === "朋友圈") {
          shareType = "shareH5PageToTimeLine";
          content = `${process.env.VUE_APP_WECHAT_SHARE}/wxShareCalculator/2/${merchantId}`;
        }
        let title = state.user.name
            ? `${state.user.name}的装修计算器`
            : "装修计算器";
        // let title = "装修计算器";
        // let desc = state.website.merchantProfile
        //     ? `${state.website.merchantProfile}`
        //     : "公司介绍";
        let desc = '';
        let param = {
          miniProgramType:state.user.jumpApplets,
          shareType: shareType,
          urlEntity: {
            title: `${process.env.VUE_APP_WECHAT_SHARE}/shareForward?shareUrl=`,
            content: content,
          },
          title: title,
          desc: desc,
          shareImg: "https://wca.ujiaku.com/upload/tangYing/fitUp_calculator.png",
        };
        flutterShowToast(param);
      }
    }
    const handleChioceArea = ()=>{
      state.showCityPicker = true
    }
    const onCityConfirm = (val) =>{
      state.selectCity = ''
      console.log(val)
      val.forEach(it=>{
        state.selectCity += it.text
      })
      state.showCityPicker = false
    }
    const customCityFieldName = {
      text: 'cityName',
      children: 'cities',
    };
    const handleBlack = () => {
      router.push({ path: "/" });
    };
    const changeNum = () =>{
      state.numList = []
      // let nums=Math.floor(Math.random()*(99999-1+1)+1);
      state.laborCost = Math.floor(Math.random()*(99999-1+1)+1);
      state.designCost = Math.floor(Math.random()*(99999-1+1)+1);
      state.materialCost = Math.floor(Math.random()*(99999-1+1)+1);
      state.qualityCost = Math.floor(Math.random()*(99999-1+1)+1);

      let nums = (state.laborCost +  state.designCost + state.materialCost + state.qualityCost)
      // let nums=(Math.random()*(9999999-1+1)+1).toFixed(2);
      let changeNums = nums.toString().split("")
      changeNums.forEach(it=>{
        state.numList.push(it)
      })
      // state.numList = JSON.parse(JSON.stringify(changeNums.split("")))
      // console.log( state.numList)
    }
    // changeNum()
    const loadSetInterval = () =>{
      setInterval(()=>{
        changeNum()
      },2000)
    };
    loadSetInterval()
    const fieldNames = {
      text: 'name',
      value: 'code',
      children: 'items',
    };
    //画图
    const savePoster = () =>{
      console.log("保存")
      state.showOverlay = true;
      // html2canvasChange()
      nextTick(() => {
        // var canvas2 = document.createElement("canvas");
        let _canvas = imageWrapper.value;
        const box = window.getComputedStyle(_canvas);
        // DOM 节点计算后宽高
        const width = parseInt(box.width, 10);
        const height = parseInt(box.height, 10);
        // 获取像素比
        const scaleBy = DPR();
        // 创建自定义 canvas 元素
        const canvas = document.createElement("canvas");
        // 设定 canvas 元素属性宽高为 DOM 节点宽高 * 像素比
        canvas.width = width * scaleBy;
        canvas.height = height * scaleBy;
        // 设定 canvas css宽高为 DOM 节点宽高
        canvas.style.width = `${width}px`;
        canvas.style.height = `${height}px`;
        html2canvas(_canvas, {
          useCORS: true,
          background: "#ffffff",
          // height: document.getElementById('saveposter').scrollHeight,
          // windowHeight: document.getElementById('saveposter').scrollHeight,
          canvas,
        }).then((canvas) => {
          var context = canvas.getContext("2d");
          context.mozImageSmoothingEnabled = false;
          context.webkitImageSmoothingEnabled = false;
          context.msImageSmoothingEnabled = false;
          context.imageSmoothingEnabled = false;
          let dataURL = canvas.toDataURL("image/png");
          state.showImg = false
          nextTick(() => {
            cardImgRef.value.style.width = width + "px";
            cardImgRef.value.style.height = height + "px";
            state.imgSrc = dataURL;
            state.showImg = false
          });
        });
      });
    }
    const DPR = () => {
      if (window.devicePixelRatio && window.devicePixelRatio > 1) {
        return window.devicePixelRatio;
      }
      return 1;
    };
    return{
      // loadSetInterval,
      handleCancel,
      imageWrapper,
      cardImgRef,
      savePoster,
      DPR,
      handleChioceType,
      onTypeConfirm,
      handleShare,
      onSelect,
      fieldNames,
      handleChioceArea,
      customCityFieldName,
      onCityConfirm,
      changeNum,
      handleBlack,
      showHeader,
      ...toRefs(state)
    }
  }
}
</script>

<style lang="scss">
.calculator-container{
  .header {
    .van-nav-bar__arrow {
      color: #333333;
    }
    .van-nav-bar__title {
      color: #333333;
      font-weight: 600;
    }
    .van-nav-bar__text {
      color: #ff6e01;
    }
  }
  .van-ellipsis{
    font-size: 14px;
  }
  .expense-inp{
    .area{
      .van-field{
        background-color: #F7F9FC;
      }
      .van-field__control{
        color: #999999;
      }
    }
  }
  .van-share-sheet__cancel::before {
    height: 0;
  }
  .van-share-sheet__cancel {
    height: 48px;
    width: 343px;
    background-color: #f7f7f7;
    color: #333333;
    border-radius: 5px;
    //    position: relative;
    //    transform: translateX(50%);
    margin-left: 15px;
    margin-bottom: 15px;
    margin-top: 15px;
  }
  .van-share-sheet__options {
    color: #333333;
    display: flex;
    padding: 0 20px;
    justify-content: space-around;
  }
  .share_box {
    .van-share-sheet__cancel::before {
      height: 0;
    }
    .van-share-sheet__cancel {
      height: 48px;
      width: 343px;
      background-color: #f7f7f7;
      color: #333333;
      border-radius: 5px;
      //    position: relative;
      //    transform: translateX(50%);
      margin-left: 15px;
      margin-bottom: 15px;
      margin-top: 15px;
    }
    .van-share-sheet__options {
      color: #333333;
      display: flex;
      padding: 0 20px;
      justify-content: space-around;
    }
  }
  .wrapperPoster{
    //margin-top: 100px;
    height: 400px;
    overflow-x: hidden;
    //position: absolute;
    position: fixed;
    top: 60px;
    //bottom: 0;
    left: 0;
    right: 0;
    .poster-details{
      background: url("../../assets/images/calculator_poster.png") no-repeat;
      background-size: cover;
      position: relative;
      left: 50%;
      transform: translateX(-50%);
      width: 250px;
      height: 360px;
      //overflow-x: hidden;
      >img{
        width: 250px;
        display: block;
      }
      .text-qrcode{
        background-color: #ffff;
        padding: 10px;
        p {
          margin: 0;
          padding: 0;
        }
        .left-text{
          margin-top: 20px;
          p:nth-child(1){
            font-size: 16px;
            font-weight: 600;
            line-height: 18px;
            color: #333333;
          }
          p:nth-child(2){
            margin-top: 5px;
            font-size: 9px;
            color: #999999;
            line-height: 20px;
          }

        }

        width: 200px;
        height: 170px;
        margin-top: 160px;
        border-radius: 8px;
        position: absolute;
        left: 50%;
        transform: translateX(-50%);
        .qrCodeUrl-all{
          //position: relative;
          //text-align: center;
          //height: 60px;
          .qrCodeUrl-cls{
            width: 70px;
            height: 70px;
            background-color: #FFFFFF;
            margin:  10px auto;
            border-radius: 35px;
            //position: relative;
            //top: -33px;
            text-align: center;
            img{
              width: 70px;
              //border-radius: 35px;
              //position: relative;
              //margin-top: 50%;
              //transform: translateY(-50%);
            }
          }
          >p{
            //position: relative;
            margin: 0;
            padding: 0;
            font-size: 9px;
            font-weight: 400;
            color: #999999;
            //top: -40px;
          }
        }
      }

    }
    .posterImg-hb{
      height: 400px;
      overflow-x: hidden;
    }
  }
}
</style>
<style lang="scss" scoped>
.calculator-container{
  font-size: 16px;
  height: 100vh;
  display: flex;
  flex-direction: column;
  .calculator-article{
    flex: 1;
    overflow-x: hidden;
    background: url("../../assets/images/calculator_bg.png") no-repeat;
    background-size: cover;
    .calculator-article-content{
      //height: 446px;
      width: 330px;
      background-color: #FFFFFF;
      margin: auto;
      margin-top: 175px;
      border-radius: 20px;
      padding: 16px;
      .title{
        //padding: 16px 0;
        display: flex;
        justify-content: center;
        align-items: center;
        font-weight: 600;
        color: #333333;
        p{
          padding: 0;
          margin: 0 10px;
        }
        span{
          display: block;
          width: 56px;
          border-bottom: 2px #333333 solid;
        }
      }
      .calculator-screen{
        width: 324px;
        height: 60px;
        position: relative;
        background-color: #b4b4b4;
        border: 3px black solid;
        border-radius: 4px;
        margin-top: 16px;
        margin-right: 0px;
        display: flex;
        align-items:center;
        justify-content: flex-end;
        .font-num{
          width: 30px;
          height: 50px;
          display: flex;
          justify-content:center;
          align-items:center;
          //background-color: #FFFFFF;
          margin-right: 4px;
          .segment{
            position:absolute;
            width: 14px;
            height: 4px;
            border-radius: 4px;
            //background-color: #898989;
            background-color: black;
          }
          .segment:nth-child(1){
            transform: translateY(-16px);
            border-radius:0 0 4px 4px;
            //border-radius: 4px;
          }
          .segment:nth-child(2){
            transform:translate(8px,-8px) rotate(90deg);
            //transform: translateY(-11px);
            border-radius:0 0 4px 4px;
            width: 12px;
          }
          .segment:nth-child(3){
            transform:translate(8px,8px) rotate(90deg);
            border-radius:0 0 4px 4px;
            width: 12px;
          }
          .segment:nth-child(4){
            transform: translateY(16px);
            border-radius:4px 4px 0 0 ;
          }
          .segment:nth-child(5){
            transform:translate(-9px,8px) rotate(90deg);
            border-radius:4px 4px 0 0;
            width: 12px;
          }
          .segment:nth-child(6){
            transform:translate(-9px,-8px) rotate(90deg);
            width: 12px;
            border-radius:4px 4px 0 0;
          }
          .segment:nth-child(7){
            border-radius:2px 2px 2px 2px;
          }
          .c_clr{
            background-color: #adadad;
          }
        }
        .point_cls{
          height: 4px;
          width: 4px;
          background-color: black;
          position: relative;
          transform: translateY(16px);
          margin-right: 10px;
        }
        .CNY_cls{
          color: black;
          font-weight: 600;
          position: relative;
          top: 10px;
          right: 5px;
        }
      }
      .cost{
        font-size: 14px;
        font-weight: 600;
        padding: 16px 0;
        display: flex;
        flex-wrap: wrap;
        justify-content: center;
        align-items: center;
        span{
          display: block;
          width: 150px;
          text-align: left;
          padding-top: 16px;
        }
      }
      .expense-inp{
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        .expense-inp-item{
          display: flex;
          flex-direction: column;
          justify-content: center;
          align-items: center;
          div{
            height: 46px;
            width: 300px;
            background-color: #F7F9FC;
            border-radius: 4px;
            color: #999999;
            font-size: 14px;
            margin-bottom: 10px;
            padding-left:16px;
            line-height: 46px;
            text-align: left;
          }
        }
        .area{
          height: 46px;
          width: 316px;
          background-color: #F7F9FC;
          border-radius: 4px;
          color: #999999;
          font-size: 14px;
          margin-bottom: 10px;
          line-height: 46px;
          text-align: left;
          display: flex;
          justify-content: space-between;
          .unit{
            margin-right: 16px;
          }
        }
      }
    }
  }
}
</style>
